<template>
  <div>
    <v-card class="mb-8">
      <div class="container container--fluid">
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.username') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-10 col-xl-11" data-matomo-mask>{{ userData.username }}</v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1 border-bottom-0">{{ $t('account.cname') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-10 col-xl-11 border-bottom-0" data-matomo-mask>{{ userData.cname }}.parkingcrew.com</v-col>
        </v-row>
      </div>
    </v-card>

    <Headline2 :text="$t('account.personal_details')" />
    <v-card class="mb-8">
      <div class="container container--fluid">
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.salutation') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-10 col-xl-11" data-matomo-mask>
            <span v-if="userData.gender == 'mr'">{{ $t('account.mr') }}</span>
            <span v-else-if="userData.gender == 'mrs'">{{ $t('account.mrs') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.first_name') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.first_name }}</v-col>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.last_name') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.last_name }}</v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1 border-bottom-0">{{ $t('account.email') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-10 col-xl-11 border-bottom-0" data-matomo-mask>{{ userData.email_address }}</v-col>
        </v-row>
      </div>
    </v-card>

    <Headline2 :text="$t('account.company_details')" />
    <v-card class="mb-8">
      <div class="container container--fluid">
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.type') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>
            <span v-if="userData.individual == 0">{{ $t('account.company') }}</span>
            <span v-else>{{ $t('account.individual') }}</span>
          </v-col>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.company') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.company_name }}</v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.address') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.street_address }}</v-col>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.address2') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.street_address2 }}</v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.postal_code') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.postcode }}</v-col>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1">{{ $t('account.city') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-4 col-xl-5" data-matomo-mask>{{ userData.city }}</v-col>
        </v-row>
        <v-row>
          <v-col class="td-title col-6 col-sm-3 col-lg-2 col-xl-1 border-bottom-0">{{ $t('account.country') }}</v-col>
          <v-col class="col-6 col-sm-9 col-lg-10 col-xl-11 border-bottom-0" data-matomo-mask>{{ userData.country }}</v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import Headline2 from '@/components/app/Headline2.vue'
import Logger from '@/util/Logger'

export default {
  name: 'AccountIndex',
  components: {
    Headline2
  },
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    }
  },
  mounted() {
    this.$store.dispatch('user/init').catch(error => {
      Logger.log('Error catched ', { error })
    })
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
.theme-default
  #account-home
    .v-card
      word-break: break-word
      .col
        line-height: 20px
        font-size: .875rem
        padding-left: 20px
        @media (max-width: 576px)
          padding-left: 12px !important
        &:not(.border-bottom-0)
          @media only screen and (min-width: 1264px)
            border-bottom: thin solid rgba(0, 0, 0, 0.12)
      .td-title
        color: $secondary-text
        font-weight: normal
</style>
