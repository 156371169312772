<template>
  <h2 class="mb-4">{{ text }}</h2>
</template>

<script>
export default {
  name: 'Headline2',
  props: {
    text: {
      required: true
    }
  }
}
</script>

<style lang="sass">
h2
  font-size: 22px
  font-weight: 500
  line-height: 32px
  letter-spacing: -0.03em
</style>
