<template>
  <div id="account-home">
    <Headline />
    <AccountIndex />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AccountIndex from '../../components/account/AccountIndex'

export default {
  components: {
    Headline,
    AccountIndex
  }
}
</script>
